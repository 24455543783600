
import { CameraFeatureFlag, CameraStatus } from "@evercam/shared/types"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"

export default {
  name: "UserTabs",
  data() {
    return {
      isProgress: false,
    }
  },
  computed: {
    ...mapStores(useCameraStore),
    showTabs() {
      return this.$route.path !== "/v2/users/signout"
    },
    userHasCameras() {
      return this.cameraStore.cameras.length > 0
    },
    userHasBimCompareCameras() {
      return this.cameraStore.cameras?.some((camera) =>
        camera.featureFlags.includes(CameraFeatureFlag.BimCompare)
      )
    },
    isAllCamerasDecommissioned() {
      return this.cameraStore.cameras.every(
        (p) => p.status === CameraStatus.Decommissioned
      )
    },
  },
  methods: {
    onTabClicked(e = {}) {
      const href = e.path?.find((el) => el.tagName === "A")?.href
      const isSameRoute = href && href === document.location.href
      if (isSameRoute) {
        return
      }

      this.isProgress = true
    },
    changed() {
      this.$setTimeout(() => (this.isProgress = false), 1000)
    },
  },
}
